import { ref } from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useAcademicYears() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const year = ref({});
    const years = ref({});

    const getAcademicYears = async () => {
        try {
            let response = await axios.get('/api/academic_years');
            years.value = response.data.data.years;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getAcademicYear = async (id) => {
        try {
            let response = await axios.get('/api/academic_years/' + id);
            year.value = response.data.data.year;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const storeAcademicYear = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/academic_years/', data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const deleteAcademicYear = async (id) => {
        try {
            await axios.delete('/api/academic_years/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    }


    const updateAcademicYear = async (data, id) => {
        errors.value = ''; //
        try {
            data.value._method = 'PATCH';
            let response = await axios.post('/api/academic_years/' + id, data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const cloneAcademicYear = async (id) => { 
        errors.value = ''; //
        try {
            let response = await axios.post('/api/academic_years/clone/'+ id);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    return {
        year,
        years,
        msgs,
        errors,
        getAcademicYear,
        getAcademicYears,
        storeAcademicYear,
        deleteAcademicYear,
        updateAcademicYear,
        cloneAcademicYear
    }
}