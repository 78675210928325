<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Academic Years</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Academic-Years' }"
              >Academic Years</router-link
            >
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9 col-sm-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Year Details</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <div class="form-group row">
                <label
                  class="col-md-12 col-form-label"
                  style="color: rgb(120, 27, 32)"
                >
                  <i class="custom-left-border"></i> Year
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Name </label>
                <label class="col-md-10 col-form-label">
                  {{ year.value }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">Start Date </label>
                <label class="col-md-10 col-form-label">
                  {{ year.start_date }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-2 col-form-label">End Date </label>
                <label class="col-md-10 col-form-label">
                  {{ year.end_date }}
                </label>
              </div>

              <div class="form-group row">
                <label
                  class="col-md-12 col-form-label"
                  style="color: rgb(120, 27, 32)"
                >
                  <i class="custom-left-border"></i> Terms
                </label>
              </div>

              <div class="form-group row">
                <div class="form-group col-md-12">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(term, index) in year.terms" :key="index">
                        <td>{{ term.name }}</td>
                        <td>{{ term.start_date }}</td>
                        <td>{{ term.end_date }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useAcademicYears from "@/composables/academic_years";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const { getAcademicYear, year } = useAcademicYears();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("edit_academic_years")) {
        router.push({
          name: "401",
        });
      } else {
        getAcademicYear(props.id);
      }
    });

    return {
      year,
    };
  },
};
</script>
